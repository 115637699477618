// ts-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */

export const PATH_QUERY = "?(";
export const PATH_FIELD = "@.";

function reconstructPropertyFilters(pathParts: string[]) {
  const reconstructedParts: string[] = [];
  let openBraces = false;
  pathParts.forEach((part) => {
    if (!part.includes("[") && !openBraces) {
      reconstructedParts.push(part);
    } else {
      if (openBraces) {
        const currentIndex = reconstructedParts.length - 1;
        reconstructedParts[currentIndex] =
          `${reconstructedParts[currentIndex]}.${part}`;
        openBraces = true;
      } else {
        openBraces = true;
        reconstructedParts.push(part);
      }

      if (part.includes("]")) {
        openBraces = false;
      }
    }
  });
  return reconstructedParts;
}

function splitPath(path: string) {
  const pathParts = path.split(".");
  return reconstructPropertyFilters(pathParts);
}

function keyIsValid(key: string) {
  if (!key) return false;
  if (key === "$") return false;
  return true;
}

export function getParametersFromQueryString(key: string): {
  propertyName: string;
  propertyValue: string;
} {
  const cleansePropertyKey = key
    .replace(PATH_QUERY, "")
    .replace(PATH_FIELD, "")
    .replace(/\)\]?/, "")
    .replaceAll("'", "")
    .trim();

  const equalsOperator = "==";
  const propertyFilter = cleansePropertyKey.split(equalsOperator);
  const propertyName = propertyFilter[0].trim();
  const propertyValue = propertyFilter[1].trim();

  return { propertyName, propertyValue };
}

function ExtractValuesByProperty(keyParts: string[], value: any, object: any) {
  const key = keyParts[0];

  const { propertyName, propertyValue } = getParametersFromQueryString(
    keyParts[1]
  );

  value = object[key].find((x: any) => x[propertyName] == propertyValue);

  return { key, value };
}

function ExtractValueUsingIndex(key: string, value: any, object: any) {
  const keyParts = key.split("[");
  key = keyParts[0];

  if (keyParts[1].includes(PATH_QUERY)) {
    return ExtractValuesByProperty(keyParts, value, object);
  }

  const index = keyParts[1].replace("]", "");
  value = object[key][Number.parseInt(index)];
  return { key, value };
}

export default function FindToken(
  path: string,
  object: any
): string | undefined {
  const parts = splitPath(path);
  for (const element of parts) {
    let key = element;
    let value = null;

    if (!keyIsValid(key)) continue;

    if (element.includes("[")) {
      const result = ExtractValueUsingIndex(key, value, object);
      value = result.value;
      key = result.key;
    } else {
      value = object[element];
    }

    if (!value) return;

    object = value;
  }

  return object;
}
